import React from 'react'
import { toast } from 'react-toastify'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import request from 'helpers/api'
import { FormContext } from 'helpers/context'
import alertsStrings from 'utils/strings/alerts'

export default class FormProvider extends React.Component {
    interval = undefined
    state = {
        form: {},
        receipt: undefined,
        status: undefined,
        ticketUrl: undefined,
        printing: false,
        timeout: false,
    }

    // get the transaction status at 2 second intervals
    fetchStatus = async () => {
        if (!this.state.receipt) return
        if (!window.terminal) return

        const response = await request({ url: 'status/' + this.state.receipt.hash })
        this.setState({ status: response.status })

        // Terminal auto refresh
        if (response.status === 'PENDING') {

            if (Date.now() - this.state.receipt.timestamp >= process.env.REACT_APP_TERMINAL_TRANSACTION_TIMEOUT) {
                // abort
                toast.error(alertsStrings.failure)
                this.setState({ timeout: true })
                this.reset()
            } else {
                // keep refreshing
                clearInterval(this.interval)
                this.interval = setInterval(
                    () => this.fetchStatus(),
                    process.env.REACT_APP_TERMINAL_TRANSACTION_REFRESH_INTERVAL
                )
            }

        } else if (response.status === 'SUCCESS') {

            // display tickets page in iframe and print it
            const { hash } = this.state.receipt

            this.setState({
                ticketUrl: process.env.REACT_APP_TICKET_URL + hash + '/preview',
            })

            this.print()

        } else { 
            // handle errors
            toast.error(alertsStrings.failure)
            this.reset()
        }
    }

    submit = (form, cb) => {
        this.setState({ form }, cb)
    }

    rememberReceipt = (receipt, cb) => {
        this.setState({ receipt }, cb)

        // sometimes ERR_CONNECTION_RESET happens out of nowhere
        // just call the API back and it should work.
        if (receipt) {
            this.fetchStatus().catch(() => {
                if (window.terminal) this.fetchStatus()
            })
        } else {
            clearInterval(this.interval)
        }
    }

    reset() {
        clearInterval(this.interval)

        setTimeout(() => {
            this.setState({
                receipt: undefined,
                status: undefined,
                ticketUrl: undefined,
                printing: false,
                timeout: false,
            })
            history.push(urls.home)
        }, 15000)
    }

    print = () => {
        setTimeout(() => {
            if (!this.state.printing) {
                this.setState({ printing: true })
                document.getElementById('tickets').contentWindow.window.print() 
                this.reset()
            }
        }, 3500)
    }

    render() {
        return (
            <FormContext.Provider
                value={{
                    ...this.state,
                    submit: this.submit,
                    rememberReceipt: this.rememberReceipt,
                }}
            >
                {this.props.children}
                {this.state.ticketUrl && <iframe
                    id="tickets"
                    title="tickets"
                    style={{ display: 'none' }}
                    src={this.state.ticketUrl}
                ></iframe>}
            </FormContext.Provider>
        )
    }
}
