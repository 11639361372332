import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

class SentryBoundary extends Component {
    state = { error: null }

    componentDidCatch(error, info) {
        this.setState({ error })
        Sentry.withScope(scope => {
            scope.setTag('source', 'SentryBoundary')
            scope.setTag('info', info)
            Sentry.captureException(error)
        })
    }

    renderErrorPage() {
        return (
            <div className="container">
                <div className="row" style={{ textAlign: 'center', height: '90vh', alignItems: 'center' }} >
                    <div className="col">
                        <div>
                            <h4>Oops, il semble y avoir eu une erreur...</h4>
                            <span className="button" onClick={() => Sentry.showReportDialog()}>Signaler un bug</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.error) {
            return this.renderErrorPage()
        }
        
        return this.props.children
    }
}

export default SentryBoundary
