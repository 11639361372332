import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import Toast from 'components/Toast'

import ErrorBoundary from 'app/Boundaries/SentryBoundary'
import DateProvider from 'app/Providers/DateProvider'
import TextProvider from 'app/Providers/TextProvider'
import FormProvider from 'app/Providers/FormProvider'

import Index from 'app/Pages/Homepage'
import ErrorPage from 'app/Pages/Error'
import Checkout from 'app/Pages/Checkout'
import Instructions from 'app/Pages/Instructions'
import Confirmation from 'app/Pages/Confirmation'
import Success from 'app/Pages/Response/Success'
import Failure from 'app/Pages/Response/Failure'

class App extends Component {
    componentDidMount() {
        history.listen((location, action) => {
            // scroll top on url change
            window.scrollTo(0, 0)
        })
    }

    render() {
        return (
            <div>
                <Toast />
                <DateProvider>
                    <TextProvider>
                        <FormProvider>
                            <Router history={history}>
                                <ErrorBoundary>
                                    <Switch>
                                        <Route
                                            exact
                                            path={urls.home}
                                            component={Index}
                                        />
                                        <Route
                                            exact
                                            path={urls.instructions}
                                            component={Instructions}
                                        />
                                        <Route
                                            exact
                                            path={urls.checkout}
                                            component={Checkout}
                                        />
                                        <Route
                                            exact
                                            path={urls.confirmation}
                                            component={Confirmation}
                                        />
                                        <Route
                                            exact
                                            path={urls.failure}
                                            component={Failure}
                                        />
                                        <Route
                                            exact
                                            path={urls.success}
                                            component={Success}
                                        />
                                        <Route path="*" component={ErrorPage} />
                                    </Switch>
                                </ErrorBoundary>
                            </Router>
                        </FormProvider>
                    </TextProvider>
                </DateProvider>
            </div>
        )
    }
}

export default App
