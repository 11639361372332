import React from 'react'
import PropTypes from 'prop-types'

import Logo from 'components/Svg/Logo'
import Parking from 'components/Svg/Parking'
import layoutStrings from 'utils/strings/layout.json'

const propTypes = {
    title: PropTypes.string,
}

const defaultProps = {
    title: layoutStrings.mainTitle,
}

const Header = ({ title }) => (
    <header className="container-fluid">
        <div className="layout-header row p-3 p-md-4 p-xl-6">
            <div className="col-4 col-sm-3 col-md-2 col-lg-1">
                <Logo />
            </div>
            <div className="col-8 col-sm-9 col-md-10 col-lg-11">
                <div className="row d-flex justify-content-center height-100">
                    <div className="d-none d-md-block">
                        <Parking  />
                    </div>
                    <div className="header-title">
                        <h1 className="header-title-text">{title}</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
)


Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
