import moment from 'moment'
import dateStrings from 'utils/strings/dates.json'

export const apiDateFormat = "YYYY-MM-DD"

export function formatForAPI(date) {
    const d = new Date(date)
    const year = d.getFullYear()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export function dateInputDates(dates) {
    let results = []

    dates.forEach(date => {
        if (date.end_date) {
            results.push({
                from: moment(date.start_date).toDate(),
                to: moment(date.end_date).toDate(),
            })
        } else {
            results.push(moment(date.start_date).toDate())
        }
    })

    return results 
}

export function displayDateRange(startString, endString = '') {
    const start = moment(startString)
    const end = moment(endString)
    const year = (end.isValid() && !end.isSame(moment(), 'year') ? end.format(dateStrings.formats.year) : '')

    // date range
    if (end.isValid() && !start.isSame(end)) {

        // add month in first date if it's different
        if (!start.isSame(end, 'month')) {
            return start.format(dateStrings.formats.fromM) + end.format(dateStrings.formats.toM) + year
        }

        return start.format(dateStrings.formats.from) + end.format(dateStrings.formats.to) + year
    }

    // single date
    return start.format(dateStrings.format) + year
}

export function displayDate(string) {
    const date = moment(string)
    if (date.isValid()) {
        return date.format(dateStrings.formats.dayLong)
    }
}
