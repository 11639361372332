import React from 'react'

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="logo-parking"
        fill={'#fff'}
    >
        <path d="M428 81.6h-25c-3.8 0-6.1 2.3-6.1 5v80.8c0 3.3 4 5 8 5s8.2-1.7 8.2-5v-28.9H427c16.4 0 29.2-7.8 29.2-28.2v-.8c0-20.4-12.4-28-28.2-28zm12 30c0 9.6-5 14.2-13 14.2h-14v-30h14c8 0 13 4.5 13 14.2v1.5zM293 113.2a124.2 124.2 0 0 0-88.3-36.6c-33.4 0-64.8 13-88.4 36.6a7.3 7.3 0 1 0 10.3 10.3A109.7 109.7 0 0 1 204.7 91c29.5 0 57.2 11.5 78 32.4a7.3 7.3 0 0 0 10.4 0 7.3 7.3 0 0 0 0-10.3z" />
        <path d="M263 143.2a82.5 82.5 0 0 0-116.6 0 7.3 7.3 0 1 0 10.3 10.4 68 68 0 0 1 96 0 7.3 7.3 0 0 0 10.3 0 7.3 7.3 0 0 0 0-10.4zM233.4 172.9a40.6 40.6 0 0 0-57.4 0 7.3 7.3 0 1 0 10.4 10.3 26 26 0 0 1 36.6 0 7.3 7.3 0 0 0 10.4 0 7.3 7.3 0 0 0 0-10.3zM359 315c-3.2-1.8-6.3-3.7-9.3-5.7l-48.3-32.6a88 88 0 0 0-49.4-15.1h-88.5a87.7 87.7 0 0 0-62.3 25.7 118.2 118.2 0 0 1-29 21.2 19 19 0 0 0 8.7 35.8h30.3a7.3 7.3 0 0 0 0-14.6H81a4.4 4.4 0 0 1-2-8.3c12-6.2 22.9-14.2 32.5-23.8a73.2 73.2 0 0 1 52-21.5h19.8v53.6h-24.2a7.3 7.3 0 0 0 0 14.6h200c6 0 10.8-4.9 10.8-10.9 0-7.7-4.2-14.7-11-18.5zm-161.1 14.7v-53.6H252c14.8 0 29 4.4 41.3 12.7l48.2 32.6a134 134 0 0 0 10.4 6.3c1 .5 1.7 1.2 2.3 2H197.9zM216 355.4h-22.6a7.3 7.3 0 0 0 0 14.6H216a7.3 7.3 0 0 0 0-14.6z" />
        <path d="M505.7 349.3l-2.3-3.9a60 60 0 0 0-39.6-27.8l-18.9-3.7v-95.5h48.8c10 0 18.2-8.1 18.2-18.2v-142c0-10-8.2-18.2-18.2-18.2h-142c-10.1 0-18.3 8.1-18.3 18.2V112a7.3 7.3 0 1 0 14.6 0V58c0-2 1.6-3.5 3.6-3.5h142c2 0 3.7 1.6 3.7 3.6v142c0 2-1.6 3.6-3.6 3.6h-142c-2 0-3.7-1.6-3.7-3.6v-40.7a7.3 7.3 0 1 0-14.6 0v40.7c0 10 8.2 18.2 18.2 18.2H407v87.9l-9.7-2a102.8 102.8 0 0 1-35-16.5l-48.3-32.6a110.2 110.2 0 0 0-61.9-19h-88.5c-29.5 0-57.2 11.5-78 32.3a95.8 95.8 0 0 1-48.2 26L27 296.7a34 34 0 0 0-26.9 33v70.7c0 8.4 5 16 12.7 19.5l29 12.9A48.1 48.1 0 0 0 89.2 472a48.2 48.2 0 0 0 46.6-35.2H378a48 48 0 0 0 46.6 35.2 48 48 0 0 0 46.5-35.2H485a27 27 0 0 0 27-27v-38c0-7.9-2.2-15.7-6.3-22.5zm-84.2-130.9h8.9V311l-9-1.8v-90.8zM113 447.5a33.6 33.6 0 0 1-47.8 0 33.9 33.9 0 0 1-6.8-37.9l.1-.3a33 33 0 0 1 2.7-4.7l.5-.7.6-.8.5-.7.7-.8.5-.5 1.1-1.3h.1l.3-.3 1-1 .2-.2 1-.9h.2c12.3-10 30.2-10 42.5 0l1.2 1 .2.2a34 34 0 0 1 2.5 2.5l.5.6.7.8a34.4 34.4 0 0 1 1.8 2.4l.1.2a33.9 33.9 0 0 1-4.4 42.4zm335.4 0a33.6 33.6 0 0 1-47.8 0 33.9 33.9 0 0 1-1.8-45.8l.5-.6 1.3-1.4a33.6 33.6 0 0 1 49 1.4l.6.6a32 32 0 0 1 2.6 3.4 33.9 33.9 0 0 1-4.4 42.4zm49-37.8c0 6.9-5.7 12.5-12.5 12.5h-12c-.3-10.6-4-20.7-10.8-29l-.1-.1-1.3-1.5-.3-.4-1.5-1.5-.2-.3a48 48 0 0 0-68.5 0l-.2.3-1.4 1.5-.3.4c-.5.5-1 1-1.3 1.5l-.2.1a47.8 47.8 0 0 0-10.7 29H137.6c-.3-10.6-4-20.7-10.8-29v-.1l-1.4-1.6-.3-.3a50 50 0 0 0-9.2-8 48.4 48.4 0 0 0-62.5 7.9l-.4.5a51.1 51.1 0 0 0-2.6 3.1c0 .2-.2.3-.3.4l-2.5 4h-.1l-1.1 2a48 48 0 0 0-5 15.6l-22.8-10.1c-2.4-1.1-4-3.5-4-6.2v-70.7c0-9 6.4-16.9 15.3-18.8l10.4-2.2c21-4.4 40.2-14.7 55.4-29.8 18.1-18.1 42.2-28 67.8-28H252c19.3 0 37.8 5.6 53.8 16.4L354 300c9.7 6.5 24.4 15.6 40.4 18.8l66.5 13.2a45.4 45.4 0 0 1 30 21l2.3 3.9c2.8 4.6 4.2 9.8 4.2 15v38z" />
        <path d="M104.9 408a22 22 0 0 0-31.3 0 22 22 0 0 0 0 31.3 22 22 0 0 0 31.3 0 22.2 22.2 0 0 0 0-31.3zm-10.3 21a7.5 7.5 0 0 1-10.7 0 7.5 7.5 0 0 1 0-10.7 7.5 7.5 0 0 1 10.7 0c3 3 3 7.7 0 10.7zM440.2 408a22 22 0 0 0-31.3 0 22.2 22.2 0 1 0 31.3 0zM430 429a7.5 7.5 0 0 1-10.7 0 7.6 7.6 0 1 1 10.7 0z" />
    </svg>
)
