import axios from 'axios'
import { toast } from 'react-toastify'

import capture from 'helpers/capture'
import alertsString from 'utils/strings/alerts.json'

/* API Config */

const baseConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_REQUEST_TIMEOUT,
}


/* Axios init */

const httpClient = axios.create(baseConfig)

// Add Interceptors
if (process.env.REACT_APP_DEBUG) {
    httpClient.interceptors.request.use(
        config => {
            // console.log(
            //     '%c ' + config.method.toUpperCase() + ' - ' + getUrl(config) + ':',
            //     'color: #0086b3; font-weight: bold',
            //     config
            // )
            return config
        },
        error => {
            return Promise.reject(error)
        }
    )

    httpClient.interceptors.response.use(
        response => {
            // console.log(
            //     '%c ' + response.status + ' - ' + getUrl(response.config) + ':',
            //     'color: #008000; font-weight: bold',
            //     response
            // )
            
            return response
        },

        error => {
            if (error.response && error.response.status) {
                console.log(
                    '%c ' +
                        error.response.status +
                        ' - ' +
                        getUrl(error.response.config) +
                        ':',
                    'color: #a71d5d; font-weight: bold',
                    error.response
                )
            }
            else if (error.code === 'ECONNABORTED') {
                console.log('%c Request timeout.', 'color: #FFA500; font-weight: bold')
            }
            else {
                console.log('%c Response undefined.', 'color: #ff0000; font-weight: bold')
            }
            return Promise.reject(error)
        }
    )
}

/* Requests */

export default function (options, handle = true) {
    const baseHeaders = { 'Content-Type': 'application/json' }
    const newOptions = {
        ...options,
        headers: {
            ...baseHeaders,
            ...options.headers,
        }

    }

    if (handle) {
        return httpClient(newOptions)
            .then(handleResponse)
            .catch(handleError)
    }

    return httpClient(newOptions)
}


/* Response handling */

export function handleResponse(success) {
    return success.data
}

export function handleError(error) {
    const { response } = error
    const data = {
        text: undefined,
        code: 0,
    }

    // timeout
    if (error.code === 'ECONNABORTED') {
        data.text = alertsString['408']
        data.code = 408
        toast.error(data.text)
        return Promise.reject(data)
    }

    // should unknown errors happen...
    if (response === undefined) {
        data.text = alertsString['0']
        capture(new Error(data.text), 'API') // send to sentry
        // sometimes ERR_CONNECTION_RESET happens out of nowhere
        // don't notify the user.
        // toast.error(data.text)
        return Promise.reject(data)
    }

    if (response.statusText !== 'OK') {
        switch (response.status) {
            case 401:
                data.text = alertsString['401']
                break;
            case 404:
                data.text = alertsString['404']
                break
            case 422:
                // free date error message is possible
                data.text = response.data || alertsString['422']
                break
            case 429:
                data.text = alertsString['429']
                break
            case 500:
                data.text = alertsString['500']
                const extras = {
                    ...response.data,
                    trace: Boolean(response.data.trace) && response.data.trace.slice(0, 12),
                }
                capture(new Error(response.data.message), 'API', extras)
                break
            default: 
                break
        }
        
        data.code = response.status

        !data.text && (
            data.text =  response.statusText
            || response.message
            || (response.data && response.data.error)
            || (response.data && response.data.errors)
            || (response.data && response.data.message)
        )

        return Promise.reject(data)
    }

    return response.data
}


/* User & token storage */

// store jwt token in local storage to keep user logged in between page refreshes
export function saveToken(user) {
    // login successful if there's a jwt token in the response
    if (user.meta.access_token) {
        sessionStorage.setItem('access_token', JSON.stringify(user.meta.access_token))
    }

    return user
}

// saving user separately from the token so it is updateable without overwriting the access_token
export function saveUser(user) {
    sessionStorage.setItem('user', JSON.stringify(user.data))
    return user
}

// updates the stored user object with the newly updated guide data
export function saveGuide(guide) {
    const user = JSON.parse(sessionStorage.getItem('user'))
    sessionStorage.setItem('user', JSON.stringify({ ...user, guide: guide.data}))
    return guide
}

function getUrl(config) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, '')
    }
    return config.url
}