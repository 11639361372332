import React from 'react'

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34.39 34.39"
        className="logo-main"
        fill={'#fff'}
    >
        <title>Collège d'Alma</title>
        <path
            d="M351.82,318.39l-8.45-13.76L337,315.11H324.72c.2.33.4.66.62,1l12.16,0,5.87-9.55,7.72,12.58C351.34,318.87,351.58,318.63,351.82,318.39Z"
            transform="translate(-322.33 -289.17)"
        />
        <path
            d="M343.37,310.08l6.26,10.2c.27-.2.54-.4.8-.61l-7.06-11.5L338,316.93l-12,0c.27.34.54.68.83,1h11.7Z"
            transform="translate(-322.33 -289.17)"
        />
        <path
            d="M343.37,301.1l-7.45,12.15H323.77c.14.34.3.67.47,1h12.24L343.37,303l9.06,14.77c.23-.26.45-.52.66-.79Z"
            transform="translate(-322.33 -289.17)"
        />
        <path
            d="M335.43,312.38l7.94-12.95,10.28,16.75a17.19,17.19,0,1,0-30.24-3.8Z"
            transform="translate(-322.33 -289.17)"
        />
        <path
            d="M343.37,311.74,339,318.82H327.67a17.19,17.19,0,0,0,21.24,1.95Z"
            transform="translate(-322.33 -289.17)"
        />
    </svg>
)
