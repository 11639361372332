import React from 'react'

import Header from 'components/Layout/Header'
import Section from 'components/Layout/Section'
import PreHeader from 'components/Layout/PreHeader'

export default ({ title, children, ...props }) => (
    <div className="px-2 px-xl-5">
        <PreHeader /> 
        <div className="layout">
            <Header title={title} />
            <Section>
                {children}
            </Section>
        </div>
    </div>
)
