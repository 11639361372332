import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Ink from 'react-ink'

const propTypes = {
    mod: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    to: PropTypes.string,
}

const defaultProps = {
    mod: '',
    disabled: false,
    active: false,
    to: undefined,
}

const SFX = () => <Ink />

class Button extends Component {
    baseClass = 'button'

    render() {
        const {
            mod,
            to,
            disabled,
            active,
            children,
            ...rest
        } = this.props

        let classes = this.baseClass

        if (Array.isArray(mod)) {
            mod.forEach(m => classes += ` ${this.baseClass}-${m}`)
        }
        else {
            classes += mod
                ? ` ${this.baseClass}-${mod}`
                : ''
        }
            
        classes += disabled
            ? ` ${this.baseClass}-disabled`
            : ''

        if (to) {
            return (
                <Link className={classes} to={to} {...rest}>
                    <SFX />
                    {children}
                </Link>
            )
        }

        return (
            <a className={classes} {...rest}>
                <SFX />
                {children}
            </a>
        )
    }
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
