import React from 'react'
import ReactLoading from 'react-loading'

import strings from 'utils/strings/checkout'

const Loading = props => (
    <div className="loading">
        <div className="loading-content">
            <ReactLoading className="loading-spinner" type="spin" />
            <div>{strings.loading}</div>
        </div>
    </div>
)

export default Loading
