import React, { Component } from 'react'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import urls from 'helpers/urls'
import { TextContext } from 'helpers/context'
import strings from 'utils/strings/instructions'
import layoutStrings from 'utils/strings/layout'
import Html from 'components/Html'
import Web from 'components/ShowWeb'
import Layout from 'components/Layout'
import Button from 'components/Button'
import FooterButton from 'components/FooterButton'

class Instructions extends Component {
    render() {
        const { texts, texts_loaded } = this.props

        return (
            <Layout title={strings.h1}>
                <div className="page-instructions">
                    <div className="content">
                        <header className="content-header">
                            <h2>{strings.h2}</h2>
                        </header>
                        <hr className="mb-5" />

                        <div>
                            <header className="">
                                <h3>{strings.instructions}</h3>
                            </header>
                            <section className="my-5">
                                <ReactPlaceholder showLoadingAnimation type='text' color={"#e4e4e4"} rows={6} ready={texts_loaded}>
                                    <Html value={texts_loaded && texts.instructions.content} />
                                </ReactPlaceholder>
                            </section>
                        </div>
                        <hr className="mb-5" />

                        <div>
                            <header className="">
                                <h3>{strings.informations}</h3>
                            </header>
                            <section className="my-5">
                                <ReactPlaceholder showLoadingAnimation type='text' color={"#e4e4e4"} rows={25} ready={texts_loaded}>
                                    <Html value={texts_loaded && texts.information.content} />
                                </ReactPlaceholder>
                            </section>
                        </div>

                        <div>
                            <div className="text-center mt-4">
                                <Button to={urls.home}>
                                    {layoutStrings.back}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Web><FooterButton /></Web>
                </div>
            </Layout>
        )
    }
}

export default props => (
    <TextContext.Consumer>
        {({ texts, texts_loaded }) => (
            <Instructions {...props} texts={texts} texts_loaded={texts_loaded} />
        )}
    </TextContext.Consumer>
)
