import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { CSSTransition } from 'react-transition-group'

import { dateInputDates, formatForAPI } from 'utils/dates'
import dateStrings from 'utils/strings/dates.json'
import layoutStrings from 'utils/strings/layout.json'

const propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    default: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
    format: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

const defaultProps = {
    value: undefined,
    default: undefined,
    format: dateStrings.format,
    disabled: false,
}

class DateInput extends Component {
    defaultState = {
        value: undefined, // displayDate(this.props.value || this.props.default),
    }
    state = this.defaultState
    mounted = false

    componentDidMount() {
        this.mounted = true
        this.init()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    handleChange = value => {
        if (this.mounted) this.props.onChange(formatForAPI(value))
        if (this.mounted) this.setState({ value })
    }

    init() {
        // returns if no date value, else it would change for today's
        if (!this.props.value) return

        // use moment to give date because new Date() outputs the wrong timezone
        const date = window.moment(this.props.value).toDate()
        // timeout-ing to fix a bug I don't understand where the component is re-rendered with the old state
        setTimeout(() => this.handleChange(date), 100)
    }

    reset() {
        if (this.mounted) this.setState(this.defaultState)
    }

    validate = value => {
        let errors = []
        if (!this.props.validation) return this.sendErrors(errors)

        // test each validation rule functions
        this.props.validation.forEach(rule => {
            errors = rule(value) ? [...errors, rule(value)] : errors
        })
        return this.sendErrors(errors)
    }

    sendErrors = errors => {
        // send value and errors to form
        this.props.validateForm({ name: this.props.name, errors })
        return errors
    }

    renderErrors = errors => {
        return [
            errors.map((error, key) => 
                <div key={key} className="alert alert-danger" role="alert">
                    {error}
                </div>)
        ]
    }
    
    render() {
        const {
            placeholder,
            name,
            touched,
            format,
            dates,
            dates_loaded,
            disabled,
        } = this.props

        const { value } = this.state

        const errors = this.validate(value)
        const hasErrors = touched && errors.length > 0
        // disable dates before today & dates from api
        const formattedDates = dateInputDates(dates)
        const disabledDays = [{ before: new Date() }, ...formattedDates]
        const className = 'form-control ' + ((!dates_loaded | disabled) ? ' input-disabled' : '')  + (hasErrors ? ' is-invalid' : '')

        return (
            <div>
                <DayPickerInput
                    onDayChange={this.handleChange}
                    value={value}
                    formatDate={formatDate}
                    format={format}
                    parseDate={parseDate}
                    placeholder={dates_loaded ? placeholder : layoutStrings.loading}
                    inputProps={{
                        className,
                        readOnly: true,
                        autoComplete: 'off',
                        name,
                    }}
                    dayPickerProps={{
                        disabledDays,
                        ...dateStrings.date_picker,
                    }}
                />
                <CSSTransition
                    in={hasErrors}
                    timeout={300}
                    className="error-transition"
                    classNames="error"
                >
                    <div>{hasErrors && this.renderErrors(errors)}</div>
                </CSSTransition>
            </div>
        )
    }
}

DateInput.propTypes = propTypes
DateInput.defaultProps = defaultProps

export default DateInput
