import React, { Component, useEffect } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { RectShape } from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'
import { toast } from 'react-toastify'

import urls from 'helpers/urls'
import request from 'helpers/api'
import history from 'helpers/browserHistory'
import strings from 'utils/strings/checkout'
import alertsStrings from 'utils/strings/alerts'
import { TICKET_PRICE, tax } from 'utils/cart'
import { apiDateFormat } from 'utils/dates'
import { TextContext, FormContext } from 'helpers/context'
import Html from 'components/Html'
import Web from 'components/ShowWeb'
import Layout from 'components/Layout'
import Button from 'components/Button'
import Loading from 'components/Loading'
import TicketList from 'components/TicketList'

const blockquotePlaceholder = (
    <div>
        <RectShape color={"#e4e4e4"} style={{ height: '7rem', width: '100%', marginBottom: '4rem' }}/>
    </div>
)

const MonerisCheckoutForm = (props) => {
    const { 
        checkout_preload_response,
        onCheckoutLoaded,
        onCheckoutCancel,
        onCheckoutSuccess,
        onCheckoutError,
    } = props;

    const { ticket, mode } = checkout_preload_response;

    useEffect(() => {
        // Instantiate the monerisCheckout object and set it up
        // eslint-disable-next-line no-undef
        const myCheckout = new monerisCheckout();
        myCheckout.setCheckoutDiv("monerisCheckout");
        myCheckout.setMode(mode);
        myCheckout.startCheckout(ticket);

        // // Set callbacks in JavaScript
        
        myCheckout.setCallback("page_loaded", onCheckoutLoaded);
        myCheckout.setCallback("cancel_transaction", onCheckoutCancel);
        myCheckout.setCallback("error_event", onCheckoutError);
        myCheckout.setCallback("payment_complete", onCheckoutSuccess);
    }, [ticket, mode]);

    return <div style={{ height: 'calc(100vh - 300px)', width: '100%'}}>
        <div id="monerisCheckout" />
    </div>;
}

class Checkout extends Component {
    constructor(props) {
        super(props)

        this.mounted = false
        this.errors = {}
        const { form } = props

        // get data from props (context) or storage
        const values = form.values || JSON.parse(sessionStorage.getItem('values'))
        const tempValues = form.tempValues
        let error = false

        // checks for required values
        if (!values || values.tickets.length < 1) {
            error = true
        }


        // if web, checks for web only values
        if (!window.terminal && (
            !values.first_name ||
            !values.last_name ||
            !values.email ||
            !tempValues
        )) {
            error = true
        }
        
        // if terminal, make sure there's a ticket with the right date
        // since we dontuse localstorage in terminal mode, reloading this page 
        // will clear all state data and we would have no tickets
        if (window.terminal) {
            if (!values.tickets[0]) {
                values.tickets = [window.moment().format(apiDateFormat)]
            }
        }

        if (error) {
            toast.info(alertsStrings.noData)
            return history.push(urls.home)
        }

        this.state = {
            values,
            tempValues,
            loading: false,
            checkout_preload_response: null,
        }
    }

    // transaction
    handleSubmit = () => {
        if (!this.state.loading) {
            this.setState({
                ...this.state,
                loading: true,
            })
    
            const url = window.terminal ? 'checkout' : 'webcheckout'

            request({
                method: 'POST',
                url: url,
                data: JSON.stringify({
                    ...this.state.values,
                    ...this.state.tempValues,
                }),
            })
                .then(this.handlePrepareSuccess)
                .catch(this.handlePrepareError)

        }
    }

    handlePrepareSuccess = async r => {
        if (!window.terminal) return this.handleWebSuccess(r)
        return this.handleTerminalSuccess(r)
    }
    
    // terminal success
    handleTerminalSuccess = async r => {
        sessionStorage.removeItem('values')
        // reset form state
        // redirect callback
        await this.props.submit({ values: undefined, tempValues: undefined })
        await this.props.rememberReceipt({
            ...r,
            timestamp: Date.now(),
        })
        await history.push(urls.confirmation)

    }

    // web success
    handleWebSuccess = async r => {
        await this.setState({
            ...this.state,
            checkout_preload_response: r,
        });
    }

    // transaction failure
    handlePrepareError = e => {
        if (window.terminal) {
            toast.error(alertsStrings.failure_terminal)
        } else {
            toast.error(e.text)
        }
        history.push(urls.home)
    }

    onCheckoutLoaded = e => {
        this.setState({
            ...this.state,
            loading: false,
        });
    }

    onCheckoutCancel = e => {
        this.setState({
            ...this.state,
            checkout_preload_response: null,
        });
    }

    onCheckoutSuccess = e => {
        request({
            method: 'POST',
            url: 'approved',
            data: JSON.stringify(this.state.checkout_preload_response),
        })
            .then(data => {
                if (data.redirect) {
                    window.location.href = data.redirect;
                }
            })
            .catch(data => {
                console.error(data);
            })
    }

    onCheckoutError = e => {
        request({
            method: 'POST',
            url: 'declined',
            data: JSON.stringify(this.state.checkout_preload_response),
        })
            .then(data => {
                if (data.redirect) {
                    window.location.href = data.redirect;
                }
            })
            .catch(data => {
                console.error(data);
            })
    }


    getTax() {        
        // calculates the prices from the selected number of tickets
        const { values } = this.state
        let subTotal = values.tickets.length * TICKET_PRICE
        subTotal = parseFloat(Math.round(subTotal * 100) / 100)
        return tax(subTotal)
    }

    render() {
        if (!this.state || !this.state.values) return null 

        const { values, loading, checkout_preload_response } = this.state
        const { texts, texts_loaded } = this.props
        const prices = this.getTax()

        const plural = values.tickets.length > 1
        const h2 = plural
            ? strings.h2_plural.replace(/{{nb}}/g, values.tickets.length)
            : strings.h2 
            
        const text = texts_loaded &&
            (window.terminal ? texts.confirmation_terminal.content : texts.confirmation.content)
                .replace(/{{email}}/g, `<strong>${values.email}</strong>`)
                .replace(/{{total}}/g, `<strong>${prices.total.toFixed(2)}&nbsp;$</strong>`)

        if (checkout_preload_response) {
            return  <Layout title={strings.h1_pay}>

                {loading && <Loading />}

                <MonerisCheckoutForm
                    checkout_preload_response={checkout_preload_response}
                    onCheckoutLoaded={this.onCheckoutLoaded}
                    onCheckoutCancel={this.onCheckoutCancel}
                    onCheckoutSuccess={this.onCheckoutSuccess}
                    onCheckoutError={this.onCheckoutError}
                />
            </Layout>
        }

        return (
            <Layout title={strings.h1}>

                {loading && <Loading />}

                <div className="page-checkout">
                    <div className="content">
                        <header className="content-header">
                            <h2>{h2}</h2>
                        </header>
                        <hr />

                        <div className="my-5">
                            <TicketList tickets={values.tickets} />
                        </div>
                        <hr />

                        <div className="row text-center">
                            <div className="col-12 mt-3 mb-5">
                                <Web>
                                    <ReactPlaceholder
                                        showLoadingAnimation
                                        color={"#e4e4e4"}
                                        customPlaceholder={blockquotePlaceholder}
                                        ready={texts_loaded}
                                    >
                                        <span />
                                    </ReactPlaceholder>
                                </Web>
                            
                                <ReactPlaceholder showLoadingAnimation type='text' color={"#e4e4e4"} rows={7} ready={texts_loaded}>
                                    <Html value={text} />
                                </ReactPlaceholder>
                            </div>

                            <div className="col-12 col-md-6 mb-1">
                                <Button
                                    disabled={loading}
                                    mod={['large', 'big']}
                                    to={urls.home}
                                >
                                    {strings.edit}
                                </Button>
                            </div>

                            <div className="col-12 col-md-6 mb-1">
                                <Button
                                    disabled={loading}
                                    mod={['large', 'big', 'large-text']}
                                    onClick={this.handleSubmit}
                                >
                                    {strings.pay}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default props => (
    <TextContext.Consumer>
        {({ texts, texts_loaded }) => (
            <FormContext.Consumer>
                {({ form, submit, rememberReceipt }) => (
                    <Checkout
                        {...props}
                        form={form}
                        texts={texts}
                        texts_loaded={texts_loaded}
                        submit={submit}
                        rememberReceipt={rememberReceipt}
                    />
                )}
            </FormContext.Consumer>
        )}
    </TextContext.Consumer>
)
