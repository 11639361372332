import * as Sentry from '@sentry/browser'
import map from 'lodash/map'

// Send error to sentry
export default (error, source, extras) => {
    Sentry.withScope(scope => {
        scope.setTag('source', source)
        map(extras, (extra, key) => {
            scope.setExtra(key, extra)
        })
        Sentry.captureException(error)
    })
}
