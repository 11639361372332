import React from 'react'
import PropTypes from 'prop-types'

import history from 'helpers/browserHistory'
import Button from 'components/Button'

import alertStrings from 'utils/strings/alerts.json'
import layoutStrings from 'utils/strings/layout.json'

const propTypes = {
    error: PropTypes.string,
}

const defaultProps = {
    error: alertStrings['404'],
}

const ErrorPage = props => (
    <div className="page-error">
        <div className="container">
            <div className="row" style={{ textAlign: 'center', height: '90vh', alignItems: 'center' }} >
                <div className="col">
                    <div>
                        <h1 className="h-margin-bottom">{props.error}</h1>
                        <div>
                            <Button onClick={history.goBack}>
                                {layoutStrings.back}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

ErrorPage.propTypes = propTypes
ErrorPage.defaultProps = defaultProps

export default ErrorPage
