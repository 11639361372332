import React from 'react'

import src from 'assets/bg-alt-min.jpg'

const Section = ({ title, children }) => (
    <section className="layout-section container-fluid">
        <div className="section-background" style={{ backgroundImage: `url(${src})` }} />
        <div className="position-relative row">
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4">
                {children}
            </div>
        </div>
    </section>
)

export default Section
