import React from 'react'

import { displayDate } from 'utils/dates'

const TicketList = props => (
    <div className="ticket-list text-center">
        {props.tickets.map((ticket, key) =>
            <div key={ticket + key} className="my-2">{displayDate(ticket)}</div>
        )}
    </div>
)

export default TicketList
