export const TPS = 0.05
export const TVQ = 0.09975
export const TICKET_PRICE = 8

export const price = num => Math.round(num * 100) / 100
export const tax = num => {
    const subTotal = price(num)
    const tps = price(subTotal * TPS)
    const tvq = price(subTotal * TVQ)
    const total = price(subTotal + tps + tvq)

    return {
        subTotal,
        tps,
        tvq,
        total,
    }
}