import React from 'react'

import ConfirmationWeb from './Web'
import ConfirmationTerminal from './Terminal'

const Confirmation = props => {
    return window.terminal
        ? <ConfirmationTerminal {...props} />
        : <ConfirmationWeb {...props} />
}

export default Confirmation
