import React from 'react'

import Button from 'components/Button'

const RemoveButton = props => (
    <Button mod={['remove']} {...props}>
        <i className="fa fa-times"></i>
    </Button>
)

export default RemoveButton
