import React from 'react'

import Title from 'components/Svg/Title'

const PreHeader = ({ title }) => (
    <header className="layout-pre-header">
        <Title />
    </header>
)

export default PreHeader
