export default {
    home: '/',
    instructions: '/instructions',
    checkout: '/paiement',
    confirmation: '/confirmation',
    failure: '/failure',
    success: '/success',
    error: {
        notfound: '/404',
    },
    external: {},
}
