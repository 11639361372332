import React, { Component } from 'react'
import { toast } from 'react-toastify'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import { FormContext } from 'helpers/context'
import confirmationStrings from 'utils/strings/confirmation'
import layoutStrings from 'utils/strings/layout'
import Layout from 'components/Layout'
import Button from 'components/Button'
import { Pending, Success, Error } from 'components/Animation'

class Confirmation extends Component {
    constructor(props) {
        super(props)
        this.receipt = props.receipt || false
        this.timeout = undefined
    }

    componentDidMount() {
        if (this.receipt) {

            // clear receipt
            // this.props.rememberReceipt(undefined)

            // redirect after 20s
            // this.timeout = setTimeout(() => history.push(urls.home), 20000)
            
        } else {
            // redirect now
            history.push(urls.home)
        }
    }

    componentWillUnmount() {
        // on page change, remove toast and redirect timeout
        toast.dismiss()
        clearTimeout(this.timeout)
    }

    render() {
        if (!this.receipt) return null

        return (
            <>
                <Layout title={confirmationStrings.h1}>
                    <div className="page-confirmation">
                        <div className="content">
                            <header className="content-header">
                                <h2>{confirmationStrings.terminal_h3}</h2>
                                <h1>{this.receipt.id}</h1>
                            </header>

                            <hr className="mb-5" />

                            <div className="text-center mb-5">
                                {(!this.props.timeout && this.props.status === 'PENDING') && (
                                    <>
                                        <Pending />
                                        <h3>{confirmationStrings.text_pending}</h3>
                                    </>
                                )}

                                {(!this.props.timeout && this.props.status === 'SUCCESS') && (
                                    <>
                                        <Success />
                                        <h3>{confirmationStrings.text_success}</h3>
                                    </>
                                )}

                                {(this.props.timeout || (this.props.status !== 'SUCCESS' && this.props.status !== 'PENDING')) && (
                                    <>
                                        <Error />
                                        <h3>{confirmationStrings.text_error}</h3>
                                    </>
                                )}
                            </div>

                            <hr/>

                            <div className="my-5">
                                <h5>Important</h5>
                                <small>{confirmationStrings.text}</small>
                            </div>

                            {this.props.status !== 'PENDING' && 
                                <div className="text-center">
                                    <Button to={urls.home}>
                                        {layoutStrings.back2}
                                    </Button>
                                    <p className="mt-3">
                                        <small>{confirmationStrings.redirect}</small>
                                    </p>
                                </div>
                            }

                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default props => (
    <FormContext.Consumer>
        {({ receipt, rememberReceipt, status, printing, timeout }) => (
            <Confirmation
                {...props}
                status={status}
                printing={printing}
                timeout={timeout}
                receipt={receipt}
                rememberReceipt={rememberReceipt}
            />
        )}
    </FormContext.Consumer>
)
