import React, { Component } from 'react'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import { TextContext } from 'helpers/context'
import strings from 'utils/strings/failure'
import layoutStrings from 'utils/strings/layout'
import Html from 'components/Html'
import Layout from 'components/Layout'
import Button from 'components/Button'

class Failure extends Component {
    render() {
        if (window.terminal) history.push(urls.home)

        const { texts, texts_loaded } = this.props
        
        return (
            <Layout title={strings.h1}>
                <div className="page-instructions">
                    <div className="content">
                        <header className="content-header">
                            <h2>{strings.h2}</h2>
                        </header>

                        <div>
                            <section className="mt-3 mb-5">
                                <ReactPlaceholder showLoadingAnimation type='text' color={"#e4e4e4"} rows={3} ready={texts_loaded}>
                                    <Html value={texts_loaded && texts.failure_page.content} />
                                </ReactPlaceholder>
                            </section>
                        </div>

                        <div>
                            <div className="text-center mt-4">
                                <Button to={urls.home}>
                                    {layoutStrings.back}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default props => (
    <TextContext.Consumer>
        {({ texts, texts_loaded }) => (
            <Failure {...props} texts={texts} texts_loaded={texts_loaded} />
        )}
    </TextContext.Consumer>
)
