import React, { Component } from 'react'
import { ToastContainer, cssTransition, } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Slide = cssTransition({
    enter: 'Toastify__slide-enter',
    exit: 'Toastify__slide-exit',
    duration: [400, 0],
    appendPosition: true
})

class AlertsComponent extends Component {
    render() {
        return <ToastContainer
            autoClose={10000}
            closeButton={false}
            closeOnClick={false}
            draggable={false}
            // hideProgressBar={true}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            position="bottom-right"
            transition={Slide}
        />
    }
}

export default AlertsComponent
