import React, { Component } from 'react'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import { TextContext } from 'helpers/context'
import strings from 'utils/strings/success'
import layoutStrings from 'utils/strings/layout'
import Html from 'components/Html'
import Layout from 'components/Layout'
import Button from 'components/Button'

class Success extends Component {
    render() {
        if (window.terminal) history.push(urls.home)

        sessionStorage.removeItem('values')
        
        const { texts, texts_loaded } = this.props

        let ticket_url;

        // get the 'h' querystring from the url
        const query = new URLSearchParams(window.location.search)
        const hash = query.get('h')

        if (hash) {
            ticket_url = process.env.REACT_APP_TICKET_URL + hash;
        }
        
        return (
            <Layout title={strings.h1}>
                <div className="page-success">
                    <div className="content">
                        <header className="content-header">
                            <h2>{strings.h2}</h2>
                        </header>

                        <div>
                            <section className="mt-3 mb-5">
                                <ReactPlaceholder showLoadingAnimation type='text' color={"#e4e4e4"} rows={3} ready={texts_loaded}>
                                    <Html value={texts_loaded && texts.success_page.content} />
                                </ReactPlaceholder>
                            </section>
                        </div>
                        
                        <div>
                        
                            {ticket_url && <div className="text-center mt-4">
                                <Button href={ticket_url} mod={["big","large-text"]} target="_blank">
                                    {layoutStrings.viewTicket}
                                </Button>
                            </div>}

                            <div className="text-center mt-4">
                                <Button to={urls.home}>
                                    {layoutStrings.back}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default props => (
    <TextContext.Consumer>
        {({ texts, texts_loaded }) => (
            <Success {...props} texts={texts} texts_loaded={texts_loaded} />
        )}
    </TextContext.Consumer>
)
