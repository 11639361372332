import React, { Component } from 'react'

import Form from 'components/Form'
import Web from 'components/ShowWeb'
import Terminal from 'components/ShowTerminal'
import Layout from 'components/Layout'
import FooterButton from 'components/FooterButton'
import strings from 'utils/strings/index'

class Homepage extends Component {
    render() {
        return (
            <Layout title={strings.h1}>
                <div className="page-index">
                    <div className="content">
                        <header className="content-header">
                            <h2>
                                <Web>{strings.h2_web}</Web>
                                <Terminal>{strings.h2_terminal}</Terminal>
                            </h2>
                        </header>
                        <Form />
                    </div>
                    <Web><FooterButton /></Web>
                </div>
            </Layout>
        )
    }
}

export default Homepage
