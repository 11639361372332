import React from 'react'

import Button from 'components/Button'
import layoutStrings from 'utils/strings/layout.json'

const FooterButton = () => (
    <Button mod={['large', 'orange']} href="https://www.collegealma.ca/">
        {layoutStrings.footerButton}
    </Button>
)

export default FooterButton
