import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import map from 'lodash/map'
import find from 'lodash/find'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import { FormContext } from 'helpers/context'
import { DateContext } from 'helpers/context'
import Web from 'components/ShowWeb'
import Button from 'components/Button'
import DateInput from 'components/DateInput'
import TextInput from 'components/TextInput'
import Terminal from 'components/ShowTerminal'
import RemoveButton from 'components/RemoveButton'
import { TICKET_PRICE, tax } from 'utils/cart'
import { formatForAPI, apiDateFormat } from 'utils/dates'
import { fields, email, required } from 'utils/validation'
import strings from 'utils/strings/index.json'
import dateStrings from 'utils/strings/dates.json'


const today = () => formatForAPI(new Date())


const Line = ({ title, children }) => (
    <div className="row line">
        <div className="col-8 col-xl-9 col-xxl-10 text-right font-weight-normal">{title}</div>
        {children && <div className="col-4 col-xl-3 col-xxl-2 text-left">{children}</div>}
    </div>
)

const propTypes = {
    values: PropTypes.object,
    byPassValidation: PropTypes.bool,
    disabled: PropTypes.bool,
    submit: PropTypes.func,
    change: PropTypes.func,
    centerButton: PropTypes.bool,
}

const defaultProps = {
    values: {},
    byPassValidation: false,
    disabled: false,
    submit: () => undefined, // blank function
    change: () => undefined, // blank function
    centerButton: false,
}

class Form extends Component {
    constructor(props) {
        super(props)

        this.interval = undefined
        this.mounted = false
        this.errors = {}
        const { form } = props

        // data to be saved in sessionStorage
        const defaultValues = {
            first_name: '',
            last_name: '',
            email: '',
            tickets: [undefined],
        }
        
        // sensitive data not saved in sessionStorage.
        const defaultTempValues = {
            receipt_email: '',
        }

        // get data from props or storage
        let values, tempValues

        if (window.terminal) {
            values = defaultValues
            tempValues = defaultTempValues
        } else {
            values = form.values ||JSON.parse(sessionStorage.getItem('values')) || defaultValues
            tempValues = defaultTempValues
        }

        this.state = {
            free: false,
            submitted: false,
            values,
            tempValues,
        }

    }

    componentDidMount() {
        this.mounted = true
        if (window.terminal) {
            this.terminalInit()
        }
    }

    componentDidUpdate() {
        if (window.terminal) {
            this.terminalInit()
        }
    }
    
    componentWillUnmount() {
        this.mounted = false
    }

    terminalInit() {
        if (!window.terminal) return
        if (this.mounted) {
            this.checkIfTodayIsFree()
        
            clearInterval(this.interval)
            this.interval = setInterval(() => this.terminalInit(), process.env.REACT_APP_TERMINAL_REFRESH_INTERVAL)
        }
    }

    checkIfTodayIsFree() {
        const m = window.moment
        const t = m(today(), apiDateFormat)
        const checkIfFree = date => {
            if (date.end_date) {
                const range = m().range(m(date.start_date), m(date.end_date))
                return range.contains(t)
            } else {
                return m(date.start_date).isSame(t, 'day')
            }
        }

        const free = this.props.dates.some(checkIfFree)
        if (this.mounted && this.state.free !== free) {
            this.setState({ free })
        }
    }

    // send form data to page
    handleSubmit = e => {
        e.preventDefault()

        // send values & errors
        this.setState({
            ...this.state,
            submitted: true,
        }, () => {
            if (this.mounted && (this.props.byPassValidation || this.handleFormValidation())) {
                // form is valid
                // store non-sensitive data
                sessionStorage.setItem('values', JSON.stringify(this.state.values))
                const valuesToSubmit = {
                    ...this.state,
                    values: window.terminal 
                        ? { tickets: [today()] }
                        : this.state.values
                }
                this.props.submit(valuesToSubmit)
                history.push(urls.checkout)
            }
        })
    }
    
    handleFieldChange = data => {
        const newDataObj = {}
        // allow nested properties in field names, eg: <input name="foo.bar" /> => data.foo.bar
        map(data, (value, property) => set(newDataObj, property, value))

        // update the state with the new form data
        this.setState(
            {
                ...this.state,
                values: {
                    ...this.state.values,
                    ...newDataObj,
                },
            },
            () => {
                if(this.mounted) this.props.change(this.state)
            }
        )
    }

    handleTempFieldChange = data => {
        const newDataObj = {}
        // allow nested properties in field names, eg: <input name="foo.bar" /> => data.foo.bar
        map(data, (value, property) => set(newDataObj, property, value))

        // update the state with the new form data
        this.setState({
            ...this.state,
            tempValues: {
                ...this.state.tempValues,
                ...newDataObj,
            },
        },
        () => {
            if(this.mounted) this.props.change(this.state)
        })
    }

    handleFormValidation = () => {
        const errors = find(this.errors, entry => entry !== undefined) || false
        return !errors
    }

    // send each field's errors to the form
    handleFieldValidation = data => {
        if (!isEmpty(data.errors)) {
            // errors are not in the state because validation is done at render
            // and it would loop infinitely
            this.errors = { ...this.errors, [data.name]: data.errors }
        } else {
            this.errors[data.name] = undefined
        }
    }

    addDay = () => {
        const { values } = this.state
        return this.handleFieldChange({
            tickets: [
                ...values.tickets,
                undefined,
            ],
        })
    }

    removeDay = () => {
        const { values } = this.state
        let tickets = values.tickets
        if (tickets.length > 1) {
            const lastDay = tickets.pop()
            this.handleFieldChange({ tickets })
            return lastDay
        }
        return false
    }

    updateDay = (key, value) => {
        const { values } = this.state
        let tickets = values.tickets
        tickets[key] = value

        return this.handleFieldChange({ tickets })
    }

    getTax() {        
        // calculates the prices from the selected number of tickets
        const { values } = this.state
        let subTotal = values.tickets.length * TICKET_PRICE
        subTotal = parseFloat(Math.round(subTotal * 100) / 100)
        return tax(subTotal)
    }

    renderCheckout() {
        const prices = this.getTax()

        const Total = () => <div>
            <hr/>
            <Line title="Sous-total :">
                <span className="fw-bold">{prices.subTotal.toFixed(2)}&nbsp;$</span>
            </Line>
            <Line title="TPS :">
                {prices.tps.toFixed(2)}&nbsp;$
            </Line>
            <Line title="TVQ :">
                {prices.tvq.toFixed(2)}&nbsp;$
            </Line>
            <hr/>
            <Line title="Total :">
                <h5 className="fw-black">{prices.total.toFixed(2)}&nbsp;$</h5>
            </Line>
        </div>

        return(
            <div className="col-12">

                <Total />           

                <div className="row text-center mt-4">

                    <div className="col-12 col-md-6 mb-1">
                        <Button mod={['large', 'big']} to={urls.instructions} >
                            {strings.instructions}
                        </Button>
                    </div>

                    <div className="col-12 col-md-6 mb-1">
                        <Button disabled={this.state.free} mod={['large', 'big', 'large-text']} onClick={this.handleSubmit} >
                            {strings.buy}
                        </Button>
                    </div>

                </div>
            </div>
        )
    }

    render() {
        const { submitted, values, tempValues, free } = this.state
        const { disabled, dates, dates_loaded } = this.props
        const formClass = `form ${disabled ? 'form--disabled' : ''}`
        const freeToday = window.terminal && free
        
        return (
            <form className={formClass} onSubmit={this.handleSubmit} autoComplete="off">

                <input type="hidden" value="first_name" />
                <input type="hidden" value="last_name" />
                <input type="hidden" value="email" />
                <input type="hidden" value="receipt_email" />

                <div className="row">
                    <Web>     
                        <div className="col-12">
                            <TextInput
                                id="first_name"
                                name="first_name"
                                label={strings.first_name}
                                value={values.first_name}
                                onChange={this.handleFieldChange}
                                validateForm={this.handleFieldValidation}
                                validation={fields.name}
                                touched={submitted}
                            />
                        </div>

                        <div className="col-12">
                            <TextInput
                                id="last_name"
                                name="last_name"
                                label={strings.last_name}
                                value={values.last_name}
                                onChange={this.handleFieldChange}
                                validateForm={this.handleFieldValidation}
                                validation={fields.name}
                                touched={submitted}
                            />
                        </div>

                        <div className="col-12">
                            <TextInput
                                id="email"
                                name="email"
                                type="email"
                                label={strings.email}
                                value={values.email}
                                onChange={this.handleFieldChange}
                                validateForm={this.handleFieldValidation}
                                validation={fields.email}
                                touched={submitted}
                            />
                        </div>
                    </Web>

                    
                    <Terminal>

                        <div className="col-12">
                            <div className="form-group">
                                <label>{strings.dateValidity}</label>
                                {/* *  terminal only * *
                                * having the day of the month as key forces a remounting of the component
                                * each time the value changes to a different date
                                * otherwise, the component keeps its internal state as value
                                * and doesn't update automatically
                                * * */}
                                <div className="row position-relative">
                                    <div className="form-col-left">
                                        <TextInput
                                            key={window.moment().format('d')}
                                            value={window.moment().format(dateStrings.format)}
                                            name="terminal_date"
                                            disabled={true} // disallow date change when terminal mode
                                        />
                                    </div>
                                    <div className="form-col-right">{TICKET_PRICE}&nbsp;$&nbsp;+&nbsp;tx</div>
                                </div>             
                            </div>

                            {freeToday &&
                                <h3 style={{ marginTop: '1rem', color: 'green', textAlign: 'center'}}>
                                    {strings.free}
                                </h3>
                            }

                        </div>   

                        <div className="col-12">
                            <TextInput
                                id="receipt_email"
                                name="receipt_email"
                                type="email"
                                label={strings.receipt_email}
                                value={tempValues.receipt_email}
                                onChange={this.handleTempFieldChange}
                                validateForm={this.handleFieldValidation}
                                validation={[email]}
                                touched={submitted}
                            />
                        </div>

                    </Terminal>

                    <Web>
                        <TransitionGroup className="day-list col-12">
                            {values.tickets.map((day, key) => {
                                const name = `date-${key}`
                                const lastItem = key > 0 && key === values.tickets.length - 1                                

                                return ( 
                                    <CSSTransition
                                        key={key}
                                        timeout={500}
                                        classNames="fade"
                                    >
                                        <div className="form-group">

                                            <label htmlFor={name}>{strings.day} {key + 1}</label>
                                            
                                            <div className="row position-relative">
                                        
                                                {lastItem && <RemoveButton onClick={this.removeDay} />}

                                                <div className="form-col-left">
                                                    <DateInput
                                                        key={key}
                                                        id={name}
                                                        name={name}
                                                        value={day}
                                                        default={day}
                                                        placeholder={strings.chooseDate}
                                                        onChange={value => this.updateDay(key, value)}
                                                        validateForm={this.handleFieldValidation}
                                                        validation={[required]}
                                                        touched={submitted}
                                                        dates={dates}
                                                        dates_loaded={dates_loaded}
                                                    />
                                                </div>
                                                <div className="form-col-right">{TICKET_PRICE}&nbsp;$&nbsp;+&nbsp;tx</div>
                                            </div>
                                            
                                        </div>
                                    </CSSTransition>
                                )
                            })}
                        </TransitionGroup>
                        <div className="form-group col-12">
                            <div className="alert alert-warning mb-3">{strings.freeNotice}</div>
                            <Button onClick={this.addDay} >
                                {strings.add}
                            </Button>
                        </div> 
                    </Web>

                    {this.renderCheckout()} 

                    
                </div>          
            </form>
        )
    }
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default props => (
    <DateContext.Consumer>
        {({ dates, dates_loaded }) =>  (
            <FormContext.Consumer>
                {({ form, submit }) => (
                    <Form
                        {...props}
                        dates={dates}
                        dates_loaded={dates_loaded}
                        form={form}
                        submit={submit}
                    />
                )}
            </FormContext.Consumer>
        )}
    </DateContext.Consumer>
)
