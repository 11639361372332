import { createBrowserHistory } from 'history'

// terminal mode from url
// get code from first param in url if it matches
const urlParams = window.location.pathname.split('/')
const regex = /(web|ga8AD6VzGOWz)/g
const findParam = param => param.match(regex)
const matches = urlParams.find(findParam)
window.terminal = matches === 'ga8AD6VzGOWz'

// todo: check ip ?
const basename = process.env.REACT_APP_SUBDIR + (window.terminal ? 'terminal/ga8AD6VzGOWz' : 'web')
export default createBrowserHistory({ basename })
