import React, { Component } from 'react'
import { toast } from 'react-toastify'

import urls from 'helpers/urls'
import history from 'helpers/browserHistory'
import { FormContext } from 'helpers/context'
import strings from 'utils/strings/confirmation'
import layoutStrings from 'utils/strings/layout'
import Layout from 'components/Layout'
import Button from 'components/Button'

class Confirmation extends Component {
    constructor(props) {
        super(props)
        this.receipt = props.receipt || false
        this.timeout = undefined
    }

    componentDidMount() {
        if (this.receipt) {

            // clear receipt
            this.props.rememberReceipt(undefined)

            // redirect after 10 seconds
            this.timeout = setTimeout(() => history.push(urls.home), 10000)
            
        } else {
            // redirect now
            history.push(urls.home)
        }
    }

    componentWillUnmount() {
        // on page change, remove toast and redirect timeout
        toast.dismiss()
        clearTimeout(this.timeout)
    }

    render() {
        if (!this.receipt) return null

        return (
            <>
                <Layout title={strings.h1}>
                    <div className="page-confirmation">
                        <div className="content">
                            <header className="content-header">
                                <h2>{strings.h2}</h2>
                            </header>

                            <hr className="mb-5" />

                            <div className="mb-5">
                                <h3>
                                    {strings.web_h3}
                                </h3>
                                <p>{strings.total} <strong>{this.receipt.total} $</strong></p>
                            </div>
                            
                            <hr/>

                            <div className="my-5">
                                <h5>Important</h5>
                                <small>{strings.text}</small>
                            </div>

                            <div className="text-center">
                                <Button to={urls.home}>
                                    {layoutStrings.back}
                                </Button>
                                <p className="mt-3">
                                    <small>{strings.redirect}</small>
                                </p>
                            </div>

                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default props => (
    <FormContext.Consumer>
        {({ receipt, rememberReceipt }) => (
            <Confirmation
                {...props}
                receipt={receipt}
                rememberReceipt={rememberReceipt}
            />
        )}
    </FormContext.Consumer>
)
