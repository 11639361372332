import moment from 'moment'

import strings from 'utils/strings/validation.json'

export const required = value =>
    (value && value !== 'null' ? value.toString().trim() : false ) ? undefined : strings.required

export const maxLength = max => value => {
    return  value && value.length > max
        ? strings.maxLength.replace('{{nb}}', max)
        : undefined
}
   
export const minLength = min => value =>
    value && value.length < min
        ? strings.minLength.replace('{{nb}}', min)
        : undefined

export const number = value =>
    value && isNaN(Number(value)) ? strings.number : undefined

export const noNumber = value =>
    value && /\d/i.test(value) ? strings.noNumber : undefined

export const minValue = min => value =>
    value && value < min ? strings.minValue : undefined

export const maxValue = max => value =>
    value && value > max ? strings.maxValue : undefined

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? strings.email
        : undefined

export const tooYoung = value =>
    value && value < 13
        ? strings.tooYoung
        : undefined

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9]/i.test(value)
        ? strings.alphaNumeric
        : undefined

export const phoneNumber = value =>
    value && !/^\d{10}$/i.test(value)
        ? strings.phoneNumber
        : undefined

export const password = value =>
    value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/i.test(value)
        ? strings.password
        : undefined

export const passwordMatch = target => value =>
    value && target !== value ? strings.passwordMatch : undefined

export const passwordDifferent = target => value =>
    value && target === value ? strings.passwordDifferent : undefined

export const latitude = value =>
    value && !/^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?))$/i.test(value)
        ? strings.latitude
        : undefined

export const longitude = value =>
    value && !/^(\+|-)?(?:180(?:(?:\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/i.test(value)
        ? strings.longitude
        : undefined

export const dateAfter = start => (date, format) => {
    const end = typeof date === 'object'
        ? moment(date)
        : moment(date, format) // use format to parse if date is not a Date object

    return date && end.isBefore(start, 'day')
        ? strings.dateAfter
        : undefined
}
    
export const postalCode = value =>
    value && !/[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]\s[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i.test(value)
        ? strings.postalCode
        : undefined

export const date = value => 
    value && !/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/i.test(value)
        ? strings.date
        : undefined


export const maxChoice = count => value =>
    value && value.length > count
        ? strings.maxChoice
        : undefined
    
export const minChoice = count => value =>
    value && value.length < count
        ? strings.minChoice
        : undefined

export const differentThan = target => value =>
    value && target === value
        ? strings.differentThan
        : undefined
    


// validation groups
export const fields = {
    name: [required, noNumber, maxLength(75)],
    email: [required, email],
    password: [required, password],
}

export default {
    fields,
    required,
    maxLength,
    minLength,
    number,
    noNumber,
    minValue,
    maxValue,
    email,
    tooYoung,
    alphaNumeric,
    phoneNumber,
    passwordMatch,
    passwordDifferent,
    latitude,
    longitude,
    dateAfter,
    postalCode,
    date,
    maxChoice,
    minChoice,
    differentThan,
}
