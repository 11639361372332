import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { init, Integrations } from '@sentry/browser'
import moment from 'moment' 
import 'moment/locale/fr'
import { extendMoment } from 'moment-range'

import * as serviceWorker from 'serviceWorker'
import App from 'app'
import 'scss/main.scss'

const ext_moment = extendMoment(moment)
window.moment = ext_moment 
window.moment.locale('fr')

if (process.env.REACT_APP_SENTRY_DSN) {
    init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        integrations: [new Integrations.Breadcrumbs({ console: false })],
    })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister()
