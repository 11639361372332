import React from 'react'

import request from 'helpers/api'
import { DateContext } from 'helpers/context'

export default class DateProvider extends React.Component {
    interval = undefined
    state = {
        dates: [],
        dates_loaded: false,
    }

    componentDidMount() {
        // sometimes ERR_CONNECTION_RESET happens out of nowhere
        // just call the API back and it should work.
        this.fetchDates().catch(() => {
            if (window.terminal) return
            this.fetchDates()
        })
    }

    fetchDates = async () => {
        const response = await request({ url: 'dates' })
        this.setState({
            dates: response.data,
            dates_loaded: true,
        })

        // Terminal auto refresh api data
        if (!window.terminal) return
        clearInterval(this.interval)
        this.interval = setInterval(() => this.fetchDates(), process.env.REACT_APP_TERMINAL_REFRESH_INTERVAL)
    }

    render() {
        return (
            <DateContext.Provider
                value={{ ...this.state }}
            >
                {this.props.children}
            </DateContext.Provider>
        )
    }
}
