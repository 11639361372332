import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    default: PropTypes.string,
    onChange: PropTypes.func,
    validateForm: PropTypes.func,
    validation: PropTypes.array,
    className: PropTypes.string,
    disabled: PropTypes.bool,
}

const defaultProps = {
    type: 'text',
    label: undefined,
    value: undefined,
    disabled: false,
    validateForm: () => null,
    default: '',
}

class TextInput extends Component {
    resetState = { value: this.props.value === null ? '' : this.props.value } // quickly fixes the uncontrolled to controlled input error
    state = this.resetState

    handleChange = e => {
        const value = this.mask(e.target.value, this.state.value) || ""
        this.props.onChange({ [this.props.name]: value })
        this.setState({ value })
    }

    reset() {
        this.setState(this.resetState)
    }

    mask = (newvalue, value) => {
        if (!this.props.mask) return newvalue
        let maskedValue = newvalue
        this.props.mask.forEach(rule => {
            maskedValue = rule(maskedValue, value)
        })
        return maskedValue
    }

    validate = value => {
        let errors = []
        if (!this.props.validation) return this.sendErrors(errors)

        // test each validation rule functions
        this.props.validation.forEach(rule => {
            errors = rule(value) ? [...errors, rule(value)] : errors
        })
        return this.sendErrors(errors)
    }

    sendErrors = errors => {
        // send value and errors to form
        this.props.validateForm({ name: this.props.name, errors })
        return errors
    }

    renderErrors = errors => {
        return [
            errors.map((error, key) => 
                <div key={key} className="alert alert-danger" role="alert">
                    {error}
                </div>)
        ]
    }

    render() {
        const {
            label,
            name,
            touched,
            type,
            placeholder,
            style,
            disabled,
            className,
        } = this.props

        const { value } = this.state
        const errors = this.validate(value)
        const hasErrors = touched && errors.length > 0
        const wrapperClasses = 'form-group' + (hasErrors ? ' is-invalid' : '')
        const inputClasses = 'form-control' + (className ? ` ${className}` : '') + (hasErrors ? ' is-invalid' : '')

        return (
            <div className={wrapperClasses}>
                {label && (
                    <label htmlFor={name}>
                        {label}
                    </label>
                )}
                
                <input
                    className={inputClasses}
                    type={type}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleChange}
                    style={style}
                    disabled={disabled}
                />
                <CSSTransition
                    in={hasErrors}
                    timeout={300}
                    className="error-transition"
                    classNames="error"
                >
                    <div>{hasErrors && this.renderErrors(errors)}</div>
                </CSSTransition>
            </div>
        )
    }
}

TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps

export default TextInput
